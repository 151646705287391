import styled from 'styled-components';
import homeIcon from '../../assets/home/homeIcon.svg';

export const Header = styled.h2`
  font-family: "Causten Extra Bold";
  font-size: 59px;
  font-weight: 500;
  line-height: 0.92;
  color: #2d2d2b;
  margin: 20% 0 12%;

  @media(max-width: 440px) {
    font-size: 43px;
  }

  @media(min-width: 781px) {
    margin: 40% 0 12%;
  }
`;

export const Bullet = styled.img.attrs({ src: homeIcon })`
  width: 21px;
  height: 21px;
  margin-right: 13px;
  margin-top: 13px;

  @media(max-width: 600px) {
    margin-top: 9px;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Text = styled.span`
  margin: 0;
  font-family: "Causten Regular";
  font-size: 28px;
  line-height: 1.54;
  text-align: left;
  color: #747474;

  @media(max-width: 600px) {
    font-size: 24px;
  }
`;
