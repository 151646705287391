import React from 'react';
import HomeCareWrapper from '../HomeCareWrapper/HomeCareWrapper';
import notebook from '../../assets/home/notebook.jpg';
import HomeBG2 from '../../assets/home/HomeBG2.png';
import * as S from '../Home3/Home3.styles';

export default () => (
  <HomeCareWrapper isReversed background={HomeBG2} img={notebook} id="patient-card" isOverlay={true}>
    <>
      <S.Header style={{margin: '38% 0 11%'}}>Wirtualna karta pacjenta</S.Header>
      <S.Text>
        Dokumentacja medyczna osoby chorej przewlekle może liczyć kilkaset stron. Jeśli przechowywać
        ją w wersji papierowej, porównywanie wielu parametrów na przestrzeni czasu jest niemal
        niemożliwe. Homecare to wygodne przechowywanie i przetwarzanie dokumentacji medycznej za
        pomocą tabletu lub komputera. Do systemu trafiają zarówno dane zgromadzone przez personel
        medyczny, wyniki badań jak i pomiary z elektronicznych czujników. Ze zgromadzonych w ten
        sposób informacji może korzystać zarówno lekarz, personel medyczny podczas wizyt domowych
        jak i sam pacjent i upoważnione przez niego osoby.
      </S.Text>
    </>
  </HomeCareWrapper>
);
