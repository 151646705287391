import styled from 'styled-components';

interface BgColorProp {
  background?: string;
}

export const Wrapper = styled.section<BgColorProp>`
  background-image: url(${(props) => props.background});
  background-fit: contain;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InnerWrapper = styled.div`
  max-width: 1600px;
  box-sizing: border-box;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-bottom: 80px;
  margin-top: 10%;

  @media (max-width: 874px) {
    flex-direction: column;
    max-width: 100%;
  }

  @media(min-width: 1400px) {
    margin-top: 335px;
  }

  @media(min-width: 874px) {
    margin-top: 15%;
  }
`;

export const Column = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 688px;
  margin-left: ${props => props.isWide ? '0px' : '20px'};
  box-sizing: border-box;

  @media (max-width: 874px) {
    margin: 0 auto 79px;
    max-width: 778px;
  }

  @media(max-width: 1400px) {
    padding: 0 16px;
  }

  max-width: ${props => props.isWide ? '788px' : '688px'};
`;

export const Header = styled.h2<any>`
  width: 100%;
  font-weight: normal;
  font-family: "Causten Extra Bold";
  font-size: 45px;
  line-height: 0.92;
  min-height: 150px;
  max-width: ${props => props.isWide ? '778px' : '653px'};

  @media(max-width: 875px) {
    height: auto;
  }

  @media(min-width: 1400px) {
    font-size: 49px;
    margin-bottom: 79px;
  }

  @media(min-width: 1225px) {
    min-height: 96px;
    margin-bottom: 49px;
  }

  @media(max-width: 1450px) {
    min-height: 130px;
    margin-bottom: 49px;
  }

  @media(max-width: 1190px) {
    min-height: 190px;
  }

  @media(max-width: 897px) {
    min-height: 205px;
  }

  @media(max-width: 874px) {
    min-height: auto;
  }
`;

export const Text = styled.p<any>`
  font-family:"Causten Regular";
  line-height: 1.59;
  color: #5e5e5e;
  max-width: ${props => props.isWide ? '685px' : '650px'};
  width: 100%;

  @media(min-width: 1400px) {
    font-size: 22px;
  }

  @media(max-width: 600px) {
    font-size: 24px;
  }

  font-size: 24px;
`;
