import React from 'react';
import HomeCareWrapper from '../HomeCareWrapper/HomeCareWrapper'
import notebook from '../../assets/home/notebook.jpg';
import HomeBG2 from '../../assets/home/HomeBG2.png';
import * as S from '../Home3/Home3.styles';

export default () => (
  <HomeCareWrapper
    background={HomeBG2}
    isReversed
    bgColor={'#EBF6FC'}
    img={notebook}
    id="about"
    isOverlay={true}
  >
    <>
      <S.Header>Telemedycyna przyszłości</S.Header>
      <S.Text>
        <strong>Homecare</strong> to kompleksowy system telemedyczny stworzony z myślą o
        mieszkających samodzielnie seniorach i osobach przewlekle chorych. Homecare łączy
        funkcjonalność czujników medycznych i przetwarzających dane w czasie rzeczywistym
        algorytmów, aby wspomagać diagnostykę, terapię oraz alarmować lekarza o sytuacjach
        zagrożenia życia.
      </S.Text>
    </>
  </HomeCareWrapper>
);
