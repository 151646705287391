import * as React from "react"
import Home1 from '../components/Home1/Home1';
import Home2 from '../components/Home2/Home2';
import Home3 from '../components/Home3/Home3';
import Home4 from '../components/Home4/Home4';
import Home5 from '../components/Home5/Home5';
import Home6 from '../components/Home6/Home6';
import Home7 from '../components/Home7/Home7';
import Home8 from '../components/Home8/Home8';
import "../components/layout.css";
const IndexPage = () => (
  <>
    <Home1 />
    <Home2 />
    <Home3 />
    <Home4 />
    <Home5 />
    <Home6 />
    <Home7 />
    <Home8 />
  </>
)

export default IndexPage
