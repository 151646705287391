import React from 'react';
import HomeCareWrapper from '../HomeCareWrapper/HomeCareWrapper';
import preasureDoctor from '../../assets/home/preasureDoctor.jpg';
import HomeBG2 from '../../assets/home/HomeBG2.png';
import * as S from '../Home3/Home3.styles';

export default () => (
  <HomeCareWrapper bgColor="#EBF6FC" isReversed img={preasureDoctor} side='center' background={HomeBG2} isOverlay={true}>
    <>
      <S.Header>Inteligentne wsparcie diagnostyki</S.Header>
      <S.Text>
        Dane, które trafiają do systemu poddawane są analizie za pomocą inteligentnego systemu
        diagnozowania i monitorowania pacjenta. Jeśli algorytmy Homecare wykryją nieprawidłowości
        mogące świadczyć o pogorszeniu się stanu zdrowia pacjenta lub konieczności wdrożenia
        dodatkowych działań terapeutycznych, lekarz prowadzący terapię otrzyma nie tylko
        powiadomienie, ale też inteligentne wsparcie diagnostyczne.
      </S.Text>
    </>
  </HomeCareWrapper>
);
