import React from 'react';
import HomeCareColumnWrapper from '../HomeCareColumnWrapper/HomeCareColumnWrapper';
import HomeBG3 from '../../assets/home/HomeBG3.png';

const data = [
  {
    title: 'Najnowsza technologia w ochronie zdrowia',
    body: `Integralnym elementem systemu są podłączone do niego czujniki instalowane na ciele lub w mieszkaniu pacjenta. Czujniki te mogą śledzić takie parametry jak praca serca, poziom cukru we krwi, oddech czy ruch. Homecare pozwala na niemal nieograniczoną indywidualizację zestawu czujników i dostosowanie ich do stanu zdrowia i indywidualnych potrzeb podopiecznego. Popularne gadżety, takie jak opaski fitnessowe mogą po podłączeniu do Homecare stać się pełnowartościowymi środkami wspomagania diagnostyki i terapii.`,
  },
  {
    title: 'Błyskawiczne powiadomienie o\u00A0sytuacjach zagrożenia życia',
    body: `W przypadku osób o ograniczonej mobilności, mieszkających samotnie lub takich, których stan zdrowia może gwałtownie ulec pogorszeniu, Homecare staje się systemem wczesnego powiadamiania o sytuacjach zagrożenia zdrowia i życia. Jeśli stanowiące rozszerzenie systemu czujniki wykryją niepokojące zmiany, powiadomiona zostanie rodzina lub personel medyczny. System alarmowy może być poszerzony o takie urządzenia jak czujniki ruchu, które uruchomią alarm jeśli podopieczny nie porusza się przez dłuższy czas czy popularne opaski do mierzenia codziennej aktywności pacjenta.`,
  },
];

export default () => (
  <HomeCareColumnWrapper
    background={HomeBG3}
    color="#404041"
    columns={data}
  />
);
