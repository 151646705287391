import React, { useState } from "react";
import logo from "../../assets/home/logo.svg";
import homeIcon from "../../assets/home/homeIcon.svg";
import notebook from "../../assets/home/notebook.jpg";
import scrollTo from "gatsby-plugin-smoothscroll";
import logoEU from "../../assets/logo-unia.jpeg";

import * as S from "./Home1.styles";

export default () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.HeadWrapper>
          <S.HeadBody>
            <S.HeaderRow></S.HeaderRow>
            <S.HeaderRow></S.HeaderRow>
            <S.LogoHeader src={logoEU} />
          </S.HeadBody>
        </S.HeadWrapper>
        <S.NavBar>
          <S.Logo src={logo} />
          <S.MobileLogo src={homeIcon} />
          <S.LinksWrapper>
            <S.StyledLink onClick={() => scrollTo("#about")}>
              O homecare
            </S.StyledLink>
            <S.StyledLink onClick={() => scrollTo("#in-practice")}>
              Homecare w praktyce
            </S.StyledLink>
            <S.StyledLink onClick={() => scrollTo("#patient-card")}>
              Wirtualna karta pacjenta
            </S.StyledLink>
            <S.StyledLink onClick={() => scrollTo("#contact")}>
              Kontakt
            </S.StyledLink>
          </S.LinksWrapper>
          {isMenuOpen ? (
            <S.LinksMobileWrapperWrapper isActive={isMenuOpen}>
              <S.StyledLink onClick={() => scrollTo("#about")}>
                O homecare
              </S.StyledLink>
              <S.StyledLink onClick={() => scrollTo("#in-practice")}>
                Homecare w praktyce
              </S.StyledLink>
              <S.StyledLink onClick={() => scrollTo("#patient-card")}>
                Wirtualna karta pacjenta
              </S.StyledLink>
              <S.StyledLink onClick={() => scrollTo("#contact")}>
                Kontakt
              </S.StyledLink>
            </S.LinksMobileWrapperWrapper>
          ) : null}
          <S.BurgerWrapper onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? <S.Close /> : <S.Burger />}
          </S.BurgerWrapper>
        </S.NavBar>
        <S.Body>
          <S.MainPhoto src={notebook} />
          <S.Column>
            <S.Header>
              <S.HeaderSemiBold>Inteligentny system wsparcia</S.HeaderSemiBold>
              <br />
              <S.HeaderBold>
                dla osób starszych i&nbsp;ich opiekunów
              </S.HeaderBold>
              <S.HeaderSmall>
                Tytuł projektu B+R: HomeCare - system wsparcia i ochrony ludzi
                starszych i przewlekle chorych w przypadkach zagrożenia życia
                oparty o inteligentne moduły komputerowe automatycznej analizy i
                monitorowania stanu zdrowia pacjenta, a także systemu
                wnioskującego wspierającego diagnostykę wybranych jednostek
                chorobowych.
              </S.HeaderSmall>
			    <S.HeaderSmall>Grantobiorca: Homecare System Sp. z o.o., Pl. Kilińskiego 2, 35-005 Rzeszów</S.HeaderSmall>
			  <S.HeaderSmall>Umowa: UMOWA O WSPARCIE (POWIERZENIE GRANTU) zgodnie z Projektem realizowanym w ramach Programu Operacyjnego Inteligentny Rozwój 2014-2020 Działanie 1.3: Prace B+R finansowane z udziałem funduszy kapitałowych Poddziałanie 1.3.1: Wsparcie Projektów badawczo-rozwojowych w fazie preseed przez fundusze typu proof of concept – BRIdge Alfa z dnia 30.01.2020</S.HeaderSmall>
              <S.HeaderSmall>Całkowity koszt realizacji Projektu Grantowego: 1 000 000,00 PLN</S.HeaderSmall>
              <S.HeaderSmall>Dofinansowanie ze środków publicznych: 800 000,00 PLN – 80% dofinansowania budżetu Projektu Grantowego (środki przekazane przez Narodowe Centrum Badań i Rozwoju z siedzibą w Warszawie)</S.HeaderSmall>
              <S.HeaderSmall>Wsparcie prywatne realizacji projektu: 200 000,00 PLN – 20% budżetu Projektu Grantowego (środki przekazane przez Polish Venture Fund Sp. z o.o. Alternatywna Spółka Inwestycyjna Sp. komandytowa z siedzibą w Łodzi)</S.HeaderSmall>
              <S.HeaderSmall>Okres realizacji: 01.03.2020 r. – 31.10.2021 r.</S.HeaderSmall>
              <S.HeaderSmall>Projekt współfinansowany z Europejskiego Funduszu Rozwoju Regionalnego w ramach Programu Operacyjnego Inteligentny Rozwój 2014-2020</S.HeaderSmall>
              <S.HeaderSmall>Działanie 1.3: Prace B+R finansowane z udziałem funduszy kapitałowych Poddziałanie 1.3.1: Wsparcie Projektów badawczo-rozwojowych w fazie preseed przez fundusze typu proof of concept – BRIdge Alfa</S.HeaderSmall>
            </S.Header>
			
          </S.Column>
        </S.Body>
      </S.InnerWrapper>
    </S.Wrapper>
  );
};
