import { GoMail } from 'react-icons/go';
import { MdClose, MdLocalPhone, MdMenu } from 'react-icons/md';
import styled from 'styled-components';
import homeBG1 from '../../assets/home/HomeBG1.png';

export const Wrapper = styled.section`
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-image: url(${homeBG1});
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const InnerWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  height: 100%;
`;

export const HeadWrapper = styled.header`
  height: 88px;
  width: 100%;
  background-color: #2dafef;
`;

export const HeadBody = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  box-sizing: border-box;

  @media(max-width: 780px) {
    display: flex;
    justify-content: space-around;
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 354px;
  width: 100%;

  @media(max-width: 780px) {
    width: auto;
  }
`;

const Icon = `
  color: #f3f4f2; 
  width: 26px; 
  height: 26px; 
  margin-right: 8px;
`;

export const PhoneIcon = styled(MdLocalPhone)`
  ${Icon}
`;

export const MailIcon = styled(GoMail)`
  ${Icon}
`;

export const Link = styled.a`
  font-family: "Causten Bold";
  font-size: 14px;
  color: #f3f4f2;
  margin: 0;
  text-decoration: none;
`;

export const NavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1450px;
  width: 100%;
  margin: 0 auto 52px;
  padding: 26px 29px 26px 0;
  box-sizing: border-box;

  @media (min-width: 780px) {
    padding-left: 26px;
  }

  @media (max-width: 500px) {
    margin-bottom: 0;
  }
`;

export const Logo = styled.img`
  object-fit: contain;
  width: auto;
  max-height: 103px;
  height: 100%;

  @media (max-width: 1146px) {
    display: none;
  }
`;

export const MobileLogo = styled.img`
  object-fit: contain;
  width: auto;
  max-height: 103px;
  height: 100%;
  margin: 0;

  @media (min-width: 1146px) {
    display: none;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 780px) {
    display: none;
  }
`;

export const Burger = styled(MdMenu)`
  color: #2dafef;
  height: 41px;
  width: 41px;
`;

export const Close = styled(MdClose)`
  color: #2dafef;
  height: 41px;
  width: 41px;
`;

export const BurgerWrapper = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin: 0;
  height: 41px;
  width: 41px;
  outline: none;

  @media(min-width: 781px) {
    display: none;
  }
`;

export const LinksMobileWrapperWrapper = styled.div<{isActive: boolean}>`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  top: 200px;
  height: calc(100vh - 200px);
  transition: right 1.2s ease-out 0s;
  width: 100%;
  height: auto;
  align-items: center;
  border-bottom: 1px solid;

  @media(min-width: 781px) {
    display: none;
  }
`;

export const StyledLink = styled.span`
  cursor: pointer;
  font-family: "Causten Bold";
  font-size: 19px;
  color: #404041;
  text-transform: uppercase;
  text-decoration: none;
  margin: auto 25px auto 0;

  @media(max-width: 920px) {
    font-size: 18px;
  }

  @media(max-width: 890px) {
    margin: auto 15px auto 0;
  }

  @media(max-width: 880px) {
    font-size: 16px;
  }

  @media(max-width: 780px) {
    margin: 14px;
  }
`;

export const Body = styled.div`
  max-width: 1470px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 0 16px;
  box-sizing: border-box;
  height: calc(100% - 260px);

  @media (max-width: 11116px) {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;

export const MainPhoto = styled.img`
  max-width: 565px;
  max-height: 565px;
  min-height: 565px;
  object-fit: cover;
  border-radius: 100%;
  flex-grow: 1;
  object-position: right;

  @media (max-width: 1382px) {
    max-width: 500px;
    min-height: 500px;
  }

  @media (max-width: 1316px) {
    max-width: 400px;
    min-height: 400px;
  }

  @media (max-width: 1208px) {
    max-width: 300px;
    min-height: 300px;
    max-height: 300px;
    margin-bottom: 37px;
  }

  @media (max-width: 700px) {
    max-width: 300px;
    min-height: 300px;
    max-height: 300px;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Column = styled.div`
  margin: auto 0 auto auto;
  max-width: 775px;
  width: 100%;

  @media (max-width: 1116px) {
    margin: auto;
  }
`;

export const Header = styled.h1``;

export const HeaderSemiBold = styled.span`
  font-weight: normal;
  font-family:"Causten Regular";
  font-size: 52px;
  line-height: 1.2;
  color: #404041;

  @media (max-width: 500px) {
    font-size: 44px;
  }
`;


export const HeaderBold = styled.span`
  font-family:"Causten Semi Bold";
  font-size: 52px;
  line-height: 1.2;
  color: #404041;

  @media (max-width: 500px) {
    font-size: 44px;
  }
`;
export const LogoHeader = styled.img`
  margin-bottom: 0;
  margin-left: auto;
`;

export const HeaderSmall = styled.div`
  padding-top: 20px;
  font-family: "Causten Regular";
  font-size: 24px;
  font-weight: 300;
  line-height: 0.92;
  @media (max-width: 1145px) {
    text-align: center;
  }
  @media (max-width: 400px) {
    font-size: 20px;
  }
`;