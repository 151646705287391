import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { GoMail } from 'react-icons/go';
import { MdLocalPhone } from 'react-icons/md';
import ContactItem from './ContactItem';
import logotype0 from '../../assets/logotype0.png';
import logotype1 from '../../assets/logotype1.png';
import logotype2 from '../../assets/logotype2.png';
import logotype3 from '../../assets/logotype3.png';
import background from '../../assets/home/home_8.png';
import * as S from './Home8.styles';

const contactItems = [
  /* {
    icon: (
      <MdLocalPhone style={{ color: '#000', width: '26px', height: '26px', marginRight: '13px' }} />
    ),
    header: 'Telefon',
    text: ['(+48) 796 686 376', '(+48) 796 686 376'],
    href: "tel:+48 796 686 376",
  }, */
  {
    icon: (
      <FaMapMarkerAlt
        style={{ color: '#000', width: '26px', height: '26px', marginRight: '13px' }}
      />
    ),
    header: 'Adres',
    text: ['HomeCare System Sp. z o.o.', 'Pl. Jana Kilińskiego 2', '35-005 Rzeszów', 'NIP: 517-040-51-41', 'KRS: 00008228249'],
  },
  {
    icon: <GoMail style={{ color: '#000', width: '26px', height: '26px', marginRight: '13px' }} />,
    header: 'E-mail',
    text: ['gosia@homecaresystem.pl'],
    href: "mailTo:gosia@homecaresystem.pl",
  },
];

export default () => (
  <S.Wrapper background={background} id="contact">
    <S.InnerWrapper>
      <S.Header>Kontakt</S.Header>  
      <S.ContactDataWrapper>
        {contactItems.map((item: any, index) => (
          <ContactItem key={index} {...item} />
        ))}
      </S.ContactDataWrapper>
    </S.InnerWrapper>
    <S.Footer>
      <S.FooterBody>
        <S.CopyRight>
          <strong>© 2021 HomeCare</strong>
        </S.CopyRight>
        <S.FoundsWrapper>
		<S.EuropeFunds src={logotype0} />
          <S.EuropeFunds src={logotype1} />
          <S.EuropeFunds src={logotype2} />
          <S.EuropeFunds src={logotype3} />
        </S.FoundsWrapper>
      </S.FooterBody>
    </S.Footer>
  </S.Wrapper>
);
