import React from 'react';
import * as WrapperStyles from '../HomeCareWrapper/HomeCareWrapper.styles';
import * as S from './HomeCareColumnWrapper.styles';

interface ColumnProps {
  title: string;
  body: string;
}

export default ({
  columns,
  color = '#2e3191',
  background,
  headerStyles = '',
}: {
  columns: ColumnProps[];
  color?: string;
  background: string;
  headerStyles?: string;
}) => (
  <S.Wrapper>
    <WrapperStyles.OverlayBackround background={background} />
    <S.InnerWrapper>
      {columns.map(({ title, body }: ColumnProps, index) => (
        <S.Column key={index} isWide={index !== 0}>
          <S.Header headerStyles={headerStyles} color={color} isWide={index !== 0}>
            {title}
          </S.Header>
          <S.Text isWide={index !== 0}>{body}</S.Text>
        </S.Column>
      ))}
    </S.InnerWrapper>
  </S.Wrapper>
);
