import React from 'react';
import * as S from './Home8.styles';

export default ({
  icon,
  header,
  text,
  href
}: {
  icon: JSX.IntrinsicElements;
  header: string;
  text: string[];
  href: string;
}) => (
  <S.ItemWrapper>
    {icon}
    {/* <S.ItemIcon src={icon} /> */}
    <S.ItemBody href={href}>
      <S.ItemHeader>{header}</S.ItemHeader>
      {text.map((row: string, index) => (
        <S.ItemText key={index}>{row}</S.ItemText>
      ))}
    </S.ItemBody>
  </S.ItemWrapper>
);
