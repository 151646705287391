import React from 'react';
import HomeCareColumnWrapper from '../HomeCareColumnWrapper/HomeCareColumnWrapper';
import HomeBG3 from '../../assets/home/HomeBG3.png';

const data = [
  {
    title: 'Transparentna komunikacja z pacjentem i jego bliskimi',
    body: `Zaufanie między personelem medycznym a pacjentem i jego rodziną jest kluczowe w prowadzeniu prawidłowej terapii. System Homecare umożliwia dokładne raportowanie zabiegów medycznych, wizyt w domu pacjenta oraz wykonywanych przez personel medyczny pomiarów. Wgląd do dokumentacji pozwala na dokładniejsze zrozumienie natury schorzeń i występujących między nimi korelacji. Dzięki temu podopieczny i jego rodzina mogą mieć pewność, że terapia prowadzona jest prawidłowo i na bieżąco monitorować jej efekty.`,
  },
  {
    title: 'Kompleksowy system dla placówek medyczno-opiekuńczych',
    body: `System Homecare został stworzony z myślą o placówkach medyczno-opiekuńczych: zarówno takich, w których pacjenci leczeni są stacjonarnie jak i tych, których personel wykonuje wizyty domowe. W jego tworzenie już na wczesnym etapie projektowania zaangażowani byli lekarze i pielęgniarki, którzy opowiedzieli nam o swoich problemach i trudnościach z operowaniem dokumentacją medyczną. Na podstawie ich opinii zaprojektowano aplikację, która pozwala jednym kliknięciem zapisywać i wizualizować dane. Obsługując wygodny interface na telefonie lub tablecie, opiekun może w pełni skupić się na pacjencie i jego stanie zdrowia.`,
  },
];

export default () => (
  <HomeCareColumnWrapper
    background={HomeBG3}
    columns={data}
  />
);
