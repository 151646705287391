import React from 'react';
import * as S from './HomeCareWrapper.styles';

export default ({
  img,
  children,
  isReversed = false,
  bgColor = '#fff',
  imgStyles = '',
  bodyStyles = '',
  background,
  wrapperStyles,
  id,
  isOverlay = false,
  side = 'right',
}: {
  bgColor?: string;
  isReversed?: boolean;
  img: string;
  children: React.ReactChild;
  imgStyles?: string;
  bodyStyles?: string;
  wrapperStyles?: string;
  background: string;
  id?: string;
  isOverlay?: boolean;
  side?: string;
}) => (
  <S.Wrapper isReversed={isReversed} wrapperStyles={wrapperStyles} bgColor={bgColor} background={isOverlay ? '' : background} id={id}>
    {isOverlay && <S.OverlayBackround background={background} />}
    <S.BodyWrapper isReversed={isReversed}>
      <S.Body bodyStyles={bodyStyles} >
        {children}
      </S.Body>
    </S.BodyWrapper>
    <S.ImageContainer>
      <S.SideImage side={side} src={img} imgStyles={imgStyles} />
    </S.ImageContainer>
  </S.Wrapper>
);
