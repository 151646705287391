import React from 'react';
import HomeCareWrapper from '../HomeCareWrapper/HomeCareWrapper';
import doctorWithSthetoscope from '../../assets/stetho.jpg';
import HomeBG3 from '../../assets/home/HomeBG3.png';
import * as S from './Home3.styles';

export default () => (
  <HomeCareWrapper background={HomeBG3} img={doctorWithSthetoscope} id="in-practice" isOverlay={true} side='left'>
    <>
      <S.Header>Homecare w&nbsp;praktyce</S.Header>
      <S.Row>
        <S.Bullet />
        <S.Text>transparentna i szybka komunikacja między lekarzem, personelem medycznym i pacjentem,</S.Text>
      </S.Row>
      <S.Row>
        <S.Bullet />
        <S.Text>wczesna diagnostyka i lepszy komfort życia seniorów,</S.Text>
      </S.Row>
      <S.Row>
        <S.Bullet />
        <S.Text>system alarmowy powiadamiający o&nbsp;sytuacjach zagrożenia życia,</S.Text>
      </S.Row>
      <S.Row>
        <S.Bullet />
        <S.Text>proste przechowywanie i przetwarzanie dokumentacji medycznej,</S.Text>
      </S.Row>
      <S.Row>
        <S.Bullet />
        <S.Text>większa wydajność pracy placówek medycznych.</S.Text>
      </S.Row>
    </>
  </HomeCareWrapper>
);
